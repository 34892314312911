import { balanceService } from '@/api/balance'
import { NETWORKS_BY_CHAINID, REFETCH_INTERVAL } from '@/constants'
import { useAppStore } from '@/store'
import { Loading } from '@/ui'
import { TitleBar } from '@/ui/titlebar'
import { localizeFiat, sortTokens } from '@/utils'
import { useQuery } from '@tanstack/react-query'

const BalanceScreen = () => {
  const { wallet, network } = useAppStore((state) => state) as any

  const balance = useQuery(['balance', wallet?._id, network], () => balanceService.Get({ address: wallet?.address }), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const balanceSum = balance.data?.result.reduce((a: any, b: any) => a + parseFloat(b.usdBalance), 0)
  if (balance.isLoading) {
    return (
      <div className="h-full bg-bakground text-foreground flex items-center justify-center gap-4 flex-col">
        <Loading className="fill-primary h-24" />
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="p-4 flex flex-col gap-4 ">
        <TitleBar />
        <h2>Account balance</h2>
        <div className="flex justify-between items-center text-[#50555C]">
          <h4>Total:</h4>
          <h4>{!balanceSum ? '-' : localizeFiat(balanceSum)}</h4>
        </div>
      </div>
      {balance.isLoading ? <Loading className="fill-primary h-24" /> : null}
      <div className="flex flex-col gap-2 flex-grow overflow-auto px-4 pb-4">
        {balance?.data?.result
          ?.filter((_: any) => parseFloat(_.balance))
          .sort(sortTokens)
          .map((balance: any) => (
            <div className="py-2 px-4 bg-white  flex justify-between shadow-[0px_10px_20px_0px_rgba(0,0,0,0.05)]">
              <div className="flex gap-4 items-center">
                <div className="relative">
                  <img className="absolute -top-1 -right-1 z-10  h-5 p-[2px] bg-white border-gray-300 border-2 rounded-full" src={(NETWORKS_BY_CHAINID as any)[balance.chainId].logo} />
                  <img className="h-8 w-8 p-[2px]  border-gray-300 border-2 rounded-full" src={balance.icon} />
                </div>
                <div className="flex flex-col">
                  <p className=" text-slate-800">{balance.symbol}</p>
                  <span className=" text-slate-500 text-xs">{balance.name}</span>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <h3 className="text-slate-600">{localizeFiat(balance.amount)}</h3>
                {/* <p className="text-slate-400">{localizeFiat(balance.amount)}</p> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
export default BalanceScreen
