import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useMutation, useQuery } from '@tanstack/react-query'
import { payWidgetService } from './pay.service'
import { useForm } from 'react-hook-form'
import { useToast } from '@/cn/ui/use-toast'
import { useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/cn/ui/select'

const PayWidget = () => {
  const [isTestnet, setNetwork] = useState(false)
  const [orders, setOrder] = useState<any>([])
  const { toast } = useToast()
  const { register, watch } = useForm()
  const { cpiid, amount, apiKey } = watch()
  const createOrder = useMutation(() => payWidgetService.Create({ cpiid, amount }, isTestnet, apiKey), {
    onSuccess: ({ data }) => {
      setOrder([...orders, data])
    },
    onError: (error: any) => {
      console.log(error)
      toast({
        variant: 'destructive',
        title: String(error?.response?.data?.message || error?.response?.data || error),
      })
    },
  })
  const orderStatus = useQuery(['order', ...orders.map((_: any) => _.orderId)], () => Promise.all(orders.map((_: any) => payWidgetService.Status(_.orderId, apiKey))), {
    enabled: !!orders.length,
    refetchInterval: 5 * 1000,
  })
  console.log(orderStatus.isFetching)

  return (
    <div className="flex flex-col gap-3 w-96 mx-auto mt-24">
      <div className="flex flex-col gap-3">
        <Input placeholder="cpiid" {...register('cpiid')} />
        <Input placeholder="amount in $" {...register('amount')} />
        <Input placeholder="api key" {...register('apiKey')} />
        <Select onValueChange={(network: string) => setNetwork(network === 'testnet')}>
          <SelectTrigger className="">
            <SelectValue placeholder="Network" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectGroup>
              <SelectItem value="testnet">Testnet</SelectItem>
              <SelectItem value="mainnet">Mainent</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <Button onClick={() => createOrder.mutate()} loading={createOrder.isLoading}>
          Request
        </Button>
      </div>
      {orderStatus.isFetching ? <span>Refetching...</span> : null}
      {orders.map((order: any, i: number) => (
        <div className="flex flex-col">
          <span>Order: {order.orderId}</span>
          <span>Status: {orderStatus.data?.[i].data.status}</span>
        </div>
      ))}
    </div>
  )
}
export default PayWidget
