import { PaymentService } from '@/api'
import { balanceService } from '@/api/balance'
import BankAccountIcon from '@/assets/icons/bank_account.svg'
import HistoryIcon from '@/assets/icons/history.svg'
import UserProfile from '@/assets/icons/user-profile.svg'
import { Button } from '@/cn/ui/button'
import { REFETCH_INTERVAL } from '@/constants'
import { useContactMap, useContactSync } from '@/hooks'
import { IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { useAppStore } from '@/store'
import { CopyButton, DP } from '@/ui'
import { SHOW, handleInvite, handleQrScan, localizeFiat, queryClient } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { Bell, ChevronRight, Contact, Download, Plus, QrCode, Search, Send, Wallet } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ShowMoreList from './components/show_more_list'
import { CreateWalletScreen } from './create_wallet'
import WalletConnectIcon from '@/assets/wallet_connect.svg'

const MODULES = [
  {
    name: 'Scan Any QR code',
    icon: <QrCode />,
    onClick: handleQrScan,
  },
  {
    name: 'Connect WC',
    icon: <img src={WalletConnectIcon} />,
    onClick: handleQrScan,
  },
  {
    name: 'Pay Contact',
    icon: <Contact />,
    to: '/dashboard/search/',
  },
  {
    name: 'Pay CPI-ID',
    icon: <Send />,
    // onClick: () => popup.open("send_by_cpi_id"),
    to: '/dashboard/send-to-cpi',
  },
  {
    name: 'Pay Address',
    icon: <Wallet />,
    // onClick: () => popup.open("send_by_ens"),
    to: '/dashboard/send-to-address',
  },
  {
    name: 'Deposit',
    icon: <Plus />,
    to: '/dashboard/deposit-funds',
  },
  {
    name: 'Receive',
    icon: <Download />,
    onClick: () => popup.open('deposit_from_eoa'),
  },
]
const Homepage = () => {
  const { wallets, is_loading, wallet, network } = useAppStore((state) => state) as any
  const user = queryClient.getQueryData(['user']) as IUser
  const balance = useQuery(['balance', wallet?._id, network], () => balanceService.Get({ address: wallet?.address }), {
    refetchInterval: REFETCH_INTERVAL,
  })

  const chainBalance = useMemo(() => {
    if (!balance.data) return
    return balance.data?.result.reduce((a: any, b: any) => a + parseFloat(b.usdBalance), 0)
  }, [balance.data?.result, user.default_chain])
  const contactMap = useContactMap()

  const navigation = useNavigate()
  const recentActivity = useQuery(['recentActivity'], () => PaymentService.RecentActivity(), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const recent = useMemo(() => {
    if (!recentActivity.data) return []
    return recentActivity.data.result.users
  }, [recentActivity.data])
  const contactsList = useMemo(() => {
    if (!useContactMap) return []
    return Object.values(contactMap)
  }, [contactMap])

  useContactSync()
  useEffect(() => {
    if (!wallet) return
    const isSkipBackup = localStorage.getItem(`skip_backup:${wallet._id}`)
    if (!wallet?.backedup_done && recentActivity.data?.result?.backupRequired && wallet?.is_local && isSkipBackup !== 'true') {
      popup.open('backup_reminder')
    } else if (wallet?.backedup_done && !wallet?.is_local && !wallet.mnemonicCipher && isSkipBackup !== 'true') {
      popup.open('recovery_reminder')
    }
  }, [wallet, recentActivity.data])
  useEffect(() => {
    if (!recentActivity.data) return
    const transaction = recentActivity.data.result.merchantPaymentRequest[0]
    if (!transaction) return
    popup.open('validate_send', {
      amount: transaction.amount,
      order: transaction,
      toAddress: transaction.receiverAddress,
      apiOrigin: transaction.apiId.origin,
    })
  }, [recentActivity.data])
  const texts = ['Pay to contact', 'Pay anyone on CPI', 'Pay to address or ENS']

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, 3000)

    return () => clearInterval(interval) // Cleanup the interval on component unmount
  }, [texts.length])

  const handleSend = async (user: any) => {
    if (user.address) {
      navigation(`/dashboard/chat/${user.address}`)
    } else {
      const userData = await PaymentService.GetUserAddressByCPID(user.username)

      navigation(`/dashboard/chat/${userData.result.address}`)
    }
  }
  const MAX_TILES = 8
  if (wallets !== null && !wallets.length && !is_loading) {
    return <CreateWalletScreen />
  }

  return (
    <div className={`relative flex flex-col h-full w-full`}>
      <div className="flex flex-col overflow-auto h-full overscrollBehaviorY-none">
        <div className="flex gap-4 items-center absolute top-0 w-full px-4 mt-4 z-10">
          <Link to="/dashboard/search" className="flex-grow flex  items-center gap-2 px-4 py-[10px] rounded-[12px] font-medium leading-tight text-primary bg-[#FFECDE]/70">
            <Search size={18} />
            {texts[currentIndex]}
          </Link>
          <div className="flex items-center gap-2">
            <Link to="/dashboard/notifications/" className="p-2 hover:bg-gray-200 active:bg-gray-100 rounded-full">
              <Bell className="text-gray-500" />
            </Link>
            <Link to="/dashboard/settings" className="h-[52px] w-[52px] shrink-0 rounded-full flex items-center justify-center text-primary active:opacity-90">
              <DP name={user.name} size="36px" className=" text-[18px]" />
            </Link>
          </div>
        </div>
        <div className="">
          <img src="/cray-hero.png" className="  z-[-1] w-full h-[236px] object-cover" />
          <div className="bg-[#FFB57F] flex justify-between py-1 px-4">
            <p>Your Balance</p>
            <span className="text-[#084887]">{chainBalance ? localizeFiat(chainBalance) : '0'}</span>
          </div>
        </div>
        <div className="flex flex-col  w-full px-4 gap-4 flex-grow">
          <div className="grid grid-cols-4">
            {MODULES.map((module) =>
              module.onClick ? (
                <button
                  key={module.name}
                  onClick={() => module.onClick({ navigation })}
                  className="flex flex-col items-center justify-start w-20 gap-2 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                >
                  <div className="h-8 w-8 flex items-center justify-center">{module.icon}</div>
                  <span className="inline leading-4 text-center text-foreground text-sm font-medium">{module.name}</span>
                </button>
              ) : (
                <Link
                  key={module.name}
                  to={module.to}
                  className="flex flex-col items-center justify-start w-20 gap-2 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                >
                  <div className="h-8 w-8  flex items-center justify-center">{module.icon}</div>
                  <span className="inline leading-4 text-center text-foreground text-sm font-medium">{module.name}</span>
                </Link>
              )
            )}
          </div>
          <div className="mx-auto px-5 py-[6px] border-black border rounded-[16px] bg-[#FCF4EF] flex gap-2">
            CPI ID: {user.username}@cray <CopyButton value={`${user.username}@cray`} iconClass="text-[#424242]" />
          </div>
          <div className="flex flex-col gap-4">
            <div {...SHOW(!!recent.length)} className="flex flex-col">
              <p className="text-foreground text-base font-medium leading-tight">Recent</p>
              <ShowMoreList
                items={recent}
                maxItems={MAX_TILES}
                renderItem={(user) => (
                  <button
                    key={user.username}
                    onClick={() => handleSend(user)}
                    className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                  >
                    <div {...SHOW(user.read_receipt === 'pending')} className="absolute bg-primary w-4 h-4 rounded-full top-1 right-1"></div>
                    {user.name ? (
                      <DP name={user.name} size="52px" className="text-[32px] border border-[#444444]" />
                    ) : (
                      <img className="h-12 w-12 rounded-full object-cover border border-[#444444]" src={UserProfile} />
                    )}
                    <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">{user.name || 'Unknown'}</span>
                  </button>
                )}
              />
            </div>
            <div {...SHOW(!!contactsList.length)} className="flex flex-col">
              <p className="text-foreground text-base font-medium leading-tight">People</p>
              <ShowMoreList
                items={contactsList}
                maxItems={MAX_TILES}
                renderItem={(user) => (
                  <button
                    key={user.username}
                    onClick={() => handleSend(user)}
                    className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                  >
                    <div {...SHOW(user.read_receipt === 'pending')} className="absolute bg-primary w-4 h-4 rounded-full top-1 right-1"></div>
                    {user.name ? (
                      <DP name={user.name} size="52px" className="text-[32px] border border-[#444444]" />
                    ) : (
                      <img className="h-12 w-12 rounded-full object-cover border border-[#444444]" src={UserProfile} />
                    )}
                    <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">{user.name || 'Unknown'}</span>
                  </button>
                )}
              />
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <Link to="transactions" className="flex gap-4 items-center">
                  <div className="flex items-center flex-grow gap-4">
                    <img src={HistoryIcon} />
                    <span className="text-black">Show transaction history</span>
                  </div>
                  <ChevronRight />
                </Link>
              </div>
              <div className="flex flex-col">
                <Link to="balance" className="flex gap-4 items-center">
                  <div className="flex items-center flex-grow gap-4">
                    <img src={BankAccountIcon} />
                    <span className="text-black">Check account balance</span>
                  </div>
                  <ChevronRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-[#fcf4ef] py-6 px-4 rounded-md mt-12">
          <p className="leading-tight text-xl">Can't find a friend? Invite & unlock bonuses!</p>
          <p className="text-gray-500">Invite users to Cray and get bonuses when they sends their first payment.</p>
          <p className="text-gray-500 flex gap-2">
            Copy referral code
            <div className=" flex gap-1">
              <b>{user.rewardProfile.referralCode}</b>
              <CopyButton value={user.rewardProfile.referralCode} />
            </div>
          </p>
          <Button onClick={handleInvite} className="w-32 rounded-full bg-[#fcf4ef] text-primary font-bold shadow-none">
            Invite
          </Button>
        </div>
      </div>

      {wallet && !wallet?.is_local ? <div className="bg-red-500 text-white top-0  w-full left-0 text-center font-bold py-2 z-10">This account is not availabel</div> : null}
    </div>
  )
}

export default Homepage
