import { balanceService } from '@/api/balance'
import { NETWORKS_BY_CHAINID } from '@/constants'
import { IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { useAppStore } from '@/store'
import { CopyButton, QR } from '@/ui'
import { TitleBar } from '@/ui/titlebar'
import { queryClient } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
const filterTokens = (chainId: number | undefined) => (_: any) => _.chainId === chainId
const sortTokens = (a: any, b: any) => parseFloat(b.usdBalance) - parseFloat(a.usdBalance)

const DepositScreen = () => {
  const user = (queryClient.getQueryData(['user']) || {}) as IUser
  const [currentNetwork, setCurrentChain] = useState(NETWORKS_BY_CHAINID?.[user?.default_chain])
  const { wallet } = useAppStore((state) => state) as any

  const balance = useQuery(['balance', wallet?._id], () => balanceService.Get({ address: wallet.address }), {})

  const tokenBalance = balance?.data?.result?.filter(filterTokens(currentNetwork?.chainId)).sort(sortTokens)

  const [sourceTokenIndex, setSourceTokenIndex] = useState(0)
  const sourceToken = tokenBalance?.length > 0 ? tokenBalance[sourceTokenIndex] : ''

  const handleChainSelect = () => {
    popup.open('select_chain', {
      currentChain: currentNetwork,
      setCurrentChain,
    })
  }
  const handleTokenSelect = () => {
    popup.open('select_token', {
      tokens: tokenBalance,
      sourceToken,
      setSourceTokenIndex,
      chainId: currentNetwork?.chainId,
    })
  }

  return (
    <div className="flex flex-col gap-4 p-4 h-full w-full">
      <TitleBar title="Deposit" navClassName="sticky top-0" />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2  px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <p>Network</p>
          </div>

          <button onClick={handleChainSelect} className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <img className="h-4 w-4" src={currentNetwork?.logo} />
              <div className="flex flex-col gap-2">
                <p>{currentNetwork?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </div>
        <div className="flex flex-col gap-2 px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <p>Coin</p>
          </div>

          <button onClick={handleTokenSelect} className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <img className="h-4 w-4" src={sourceToken?.icon} />
              <div className="flex flex-col gap-2">
                <p>{sourceToken?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </div>
      </div>
      <div>
        <p>Address</p>
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <p className="w-[214px]  break-words">{wallet?.address}</p>
            <CopyButton value={wallet?.address} />
          </div>
          <QR data={wallet?.address} className="w-[80px]" />
        </div>
      </div>
    </div>
  )
}
export default DepositScreen
