import { OrderStatus } from '@/interface'
import { useMemo } from 'react'
import { CheckCircle, CircleX, Loader } from 'lucide-react'

const TransactionStatusIcon = ({ status }: { status: string }) => {
  let icon = useMemo(() => {
    switch (status) {
      case OrderStatus.PROCESSING:
      case OrderStatus.SIGNED:
      case OrderStatus.ASSIGNED:
        return <Loader className=" animate-spin-slow " size={18} />
      case OrderStatus.COMPLETED:
        return <CheckCircle color="#30b532" size={18} />
      case OrderStatus.FAILED:
      case OrderStatus.DECLINED:
        return <CircleX color="#e30026" />
      default:
        return ''
    }
  }, [status])
  return icon
}
export default TransactionStatusIcon
