import { Button } from '@/cn/ui/button'

const ServerConnectionErrorScreen = () => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <img src="/error.png" className="px-8" />
      <h3 className="mt-4  text-[#e30026]">Can't Connect to Server</h3>
      <p className="text-center text-gray-600">Cannot Connect to server, please try again after sometime</p>
      <Button onClick={() => ((window as any).location = '')} className="mt-8" variant="destructive">
        Try Again
      </Button>
    </div>
  )
}
export default ServerConnectionErrorScreen
