import { FCMService } from '@/api'
import { queryClient } from '.'

const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/)
class ReactNativeBridge {
  counter = 0
  promises = new Map()
  isBridge = () => (window as any).NATIVE_BRIDGE
  triggers = new Map()

  constructor() {
    const handleMessage = ({ data }: any) => {
      try {
        const message = JSON.parse(data)
        this.triggerEvent(message.type, message.payload)
        if (message.type === 'BRIDGE_RESOLVE') {
          this.promises.get(message.counter).resolve(message.payload)
        } else if (message.type === 'BRIDGE_REJECT') {
          this.promises.get(message.counter).reject(message.payload)
        } else if (message.type === 'EXPO_TOKEN') {
          ;(window as any).EXPO_TOKEN = message.payload
          FCMService.SYNC(message.payload)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (_iOSDevice) {
      window.addEventListener('message', handleMessage)
    } else {
      document.addEventListener('message', handleMessage)
    }
  }
  triggerEvent = (type: string, payload: any) => {
    const callbacks = this.triggers.get(type)
    if (callbacks) {
      ;[...callbacks].forEach((cb: Function) => cb(payload))
    }
  }
  addEventListener = (key: string, cb: Function) => {
    if (!this.triggers.has(key)) {
      this.triggers.set(key, new Set())
    }
    this.triggers.get(key).add(cb)
  }
  removeEventListener = (key: string, cb: Function) => {
    this.triggers.get(key).remove(cb)
  }
  Send = (key: string, data?: any): any => {
    const counter = this.counter++
    const { _id: userId } = queryClient.getQueryData(['user']) || ({} as any)
    const p = new Promise((resolve, reject) => {
      this.promises.set(counter, { resolve, reject })
      ;(window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'BRIDGE',
          counter,
          payload: {
            key,
            data,
            userId,
          },
        })
      )
    })
    return p
  }
}
export const NativeBridge = new ReactNativeBridge()
