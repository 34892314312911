import { useToast } from '@/cn/ui/use-toast'
import { ERROR_MAP } from '@/constants'
import { DP, Loading } from '@/ui'
import { ACTIVE_CURRENCY, SHOW, parseErrorMessage, prettifyAddress } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { ChevronRight, X } from 'lucide-react'
import { useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { popup } from '@/popup_handler'

const SendMoney = () => {
  let data = useLocation()
  const { toast } = useToast()
  const navigate = useNavigate()
  const props = data.state
  const handleBack = () => {
    if (props.username) {
      navigate(`/dashboard/chat/${props.address}`)
    } else {
      navigate('/dashboard')
    }
  }

  const [amount, setAmount] = useState('')
  const [remark, setRemark] = useState('')

  const handleContinue = useMutation(
    async () => {
      if (!amount) {
        return toast({ variant: 'destructive', title: 'Please enter amount' })
      } else if (Number(amount) / ACTIVE_CURRENCY.conversion_rate < 0.1 && !import.meta.env.DEV) {
        return toast({
          variant: 'destructive',
          title: `Amount should not be less than ${ACTIVE_CURRENCY.conversion_rate * 0.1} ${ACTIVE_CURRENCY.symbol}`,
        })
      }

      popup.open('validate_send', {
        contact: props,
        toChain: props.default_chain,
        amount: Number(amount) / ACTIVE_CURRENCY.conversion_rate,
        remark,
        cpiid: props.username,
        userId: props.userId,
        order: props.order,
      })
    },
    {
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: parseErrorMessage(error, ERROR_MAP),
        })
      },
    }
  )
  if (!props) {
    return <Navigate to="/dashboard/" />
  }
  return (
    <div title="Select contact" className={`relative flex flex-col justify-between h-full  text-foreground  p-0 overflow-hidden`}>
      <div className="p-4">
        <button onClick={handleBack} className="flex items-center justify-center cursor-pointer hover:bg-background rounded-full p-2 active:opacity-70">
          <X />
        </button>
      </div>
      <div className="flex items-center justify-center h-full flex-col gap-8 text-foreground">
        {props?.name || props.name ? <DP name={props?.name || props.name} size="42px" className="text-[24px] -mt-12" /> : null}

        <div className="flex flex-col gap-4 items-center">
          <p className=" text-[18px] font-medium">Paying {!props?.name ? ' Unknown' : props?.name}</p>
          <p className="text-[18px]" {...SHOW(!!props.username)}>
            CPI ID: {props.username}@cray
          </p>
          <p className=" tracking-[-2%]">{prettifyAddress(props.address)}</p>
        </div>
        {/* <h5>Paying {props.name ? props.name : prettifyAddress(props.address)}</h5>
                {props.name && <p className="flex gap-2 items-center font-bold rounded-[6px] inline-code">{prettifyAddress(props.address)}</p>}
                {props.username && <p>CPI-ID: {props.username.toLowerCase()}@cray</p>} */}
        <label className="w-full flex items-center justify-center">
          <div className="flex gap-2 items-center text-4xl">
            {ACTIVE_CURRENCY.symbol}
            <input
              type="number"
              inputMode="decimal"
              onChange={(e) => {
                if ((e.target.value?.split('.')?.at(1)?.length || 0) <= 2) {
                  e.target.style.width = (e.target.value.length + 1) * 20 + 'px'
                  setAmount(e.target.value)
                }
              }}
              value={amount !== undefined ? amount : ''}
              className="outline-none w-6"
              placeholder="0"
              autoFocus
            />
          </div>
        </label>
        <input
          type="text"
          className="outline-none w-36 bg-gray-100 px-6 py-2 rounded-xl text-center transition-all duration-150 "
          placeholder="Add a note"
          onChange={(e) => {
            e.target.style.width = e.target.value.length > 10 ? '90%' : '144px'
            setRemark(e.target.value)
          }}
          value={remark}
        />
      </div>
      <button onClick={() => handleContinue.mutate()} className="absolute bottom-4 right-4 bg-primary p-3 rounded-xl text-white">
        {handleContinue.isLoading ? <Loading /> : <ChevronRight />}
      </button>
    </div>
  )
}
export default SendMoney
