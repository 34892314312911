import { ContactService, HistoryService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useContactMap } from '@/hooks'
import { IContact, ITx } from '@/interface'
// import { popup } from "@/popup_handler"
import { DP } from '@/ui'
import { HIDE, SHOW, handleInvite } from '@/utils'
// import { NativeBridge } from "@/utils/native_bridge"
import { useQuery } from '@tanstack/react-query'
import { ArrowLeft, Share } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const SearchBar = () => {
  const [q, setQ] = useState('')
  // const onChange = (contact: IContact) => popup.open("chat", contact)
  const transactions = useQuery(['transactions'], () => HistoryService.Get())

  const contacts = useQuery(['contacts'], () => ContactService.GetAll())
  const navigate = useNavigate()
  const handleSelect = async (account: IContact) => {
    if (account.exists) {
      navigate('/dashboard/send-money', { state: account })
    } else {
      handleInvite()
    }
  }
  const contactMap = useContactMap()
  const recentContactTx = useMemo(() => {
    if (!transactions.data) return []
    const recentContacts = transactions.data.map((tx: ITx) => contactMap?.[tx.receiverAddress]).filter((_: any) => _)
    return [...new Map(recentContacts.map((item: any) => [item.username, item])).values()]
  }, [contactMap, transactions.data])

  return (
    <div className="flex flex-col bg-background text-foreground h-full rounded-none px-4 py-2 w-full">
      <Input
        onChange={(e) => setQ(e.target.value)}
        value={q}
        icon={<ArrowLeft onClick={() => navigate(-1)} className=" cursor-pointer " />}
        className="rounded-full bg-[#D9D9D91A]"
        placeholder="Pay anyone on CPI"
      />
      <div className="flex  flex-col flex-grow overflow-scroll">
        <div {...HIDE(!!q || transactions.isLoading || recentContactTx.length === 0)} className="flex flex-col mt-4">
          <h3 className="mb-4">Recents</h3>
          {recentContactTx
            ?.slice(0)
            .slice(-5)
            ?.map((contact: any) => (
              <Link
                to="/dashboard/send-money"
                state={contact}
                className={`flex items-center  justify-between gap-4 bg-background shrink-0 hover:bg-gray-100 active:bg-primary/20 rounded-[8px] p-4 cursor-pointer disabled:opacity-15 `}
              >
                <DP name={contact.name || contact.email} size="42px" className="text-[24px]" />
                <div className="flex justify-between w-full gap-1 items-start">
                  <div className="flex flex-col items-start">
                    <span className="text-foreground">{contact?.name || contact?.email}</span>
                    <span {...SHOW(!!contact?.username)} className=" text-foreground/50">
                      {contact?.username}@cray
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <div className="flex flex-col mt-4">
          <h3 className="mb-4">All people on CPI</h3>
          {[...(contacts.data || [])]
            ?.sort((a) => (a?.exists ? -1 : 1))
            ?.filter((contact) => contact?.name?.toLowerCase().includes(q.toLowerCase()) || contact?.username?.toLowerCase().includes(q.toLowerCase()))
            .map((contact: IContact) => (
              <button
                onClick={() => handleSelect(contact)}
                className={`flex items-center  justify-between gap-4 bg-background shrink-0 hover:bg-gray-100 active:bg-primary/20 rounded-[8px] p-4 cursor-pointer disabled:opacity-15 `}
              >
                <DP name={contact.name || contact.email} size="42px" className="text-[24px]" />
                <div className="flex justify-between w-full gap-1 items-start">
                  <div className="flex flex-col items-start">
                    <span className="text-foreground">{contact?.name || contact.email}</span>
                    <span {...SHOW(!!contact?.username)} className=" text-foreground/50">
                      {contact?.username}@cray
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-500" {...HIDE(contact?.exists || false)}>
                    <Share size={18} />
                    Invite
                  </div>
                </div>
              </button>
            ))}
          {contacts.data?.length === 0 ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col items-start gap-3">
                <div>Please sync your contacts to pay and receive money from your contact</div>
                <Button onClick={() => navigate('/dashboard/select-contact')}>Sync</Button>
              </div>
              <div className="flex flex-col items-start gap-3">
                <div>Can’t find a friend? Invite a friend to win exciting gifts.</div>
                <Button onClick={handleInvite}>Invite</Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default SearchBar
