import { get } from '@/utils/xhr'
export enum NotificationType {
  'MESSAGE' = 'MESSAGE',
  'TRANSACTION_IN' = 'TRANSACTION_IN',
  'TRANSACTION_OUT' = 'TRANSACTION_OUT',
}
export interface INotification {
  _id: string
  title: string
  description: string
  user: string
  type: NotificationType
  createdAt: string
  updatedAt: string
  metadata: {
    [key: string]: string
  }
}
class NotificationService {
  GetAll = () => {
    return get('/notifications')
  }
}
export const notificationService = new NotificationService()
