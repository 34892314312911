export * from './token_list'
import { IChain } from '@/interface'
import { JsonRpcProvider } from 'ethers'

export const CRAY_TRANSFER_ADDRESS = { MAINNET: '0x9077f79E3EC998Dc10c55F9C1998D66c2636e7Aa', TESTNET: '0x3CFCBd9BF76125AfaFF895711384110EaFB1Fb7c' }
export const REFETCH_INTERVAL = 1000 * 15
export enum AUTHENTICATION_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
export const ERROR_MAP = {
  'Error: insufficient funds': 'Error: insufficient Gas fee',
  'transfer amount exceeds balance': 'Error: insufficient balance',
  '429': 'App has exceeded its compute units per second capacity',
  'replacement fee too low': 'Replacement fee too low',
  'Low Gas Fee': 'insufficient Gas fee',
  "You don't have sufficient gas": "You don't have sufficient gas to complete this payment.",
  'Order Not Found': "This Payment request does't exists",
}
export const NATIVE_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export enum CHAIN_ID {
  POLYGON = 137,
  MAINNET = 1,
  XDAI = 100,
  ARBITRUM = 42161,
  FANTOM = 250,
  OPTIMISM = 10,
  AVAX = 43114,
  BSC = 56,
  AURORA = 1313161554,
  BASE = 8453,
  AVAX_CHAIN_ID = 43114,
  POLYGON_ZKEVM_TESTNET = 2442,
  ETH_SEPOLIA = 11155111,
  POLYGON_ZKEVM_CARDONA = 2442,
  OP_SEPOLIA_CHAIN_ID = 11155420,
  ARB_SEPOLIA_CHAIN_ID = 421614,
  BASE_SEPOLIA_CHAIN_ID = 84532,
  ZKSYNC_ERA_CHAIN_ID = 324,
  POLYGON_ZKEVM_CHAIN_ID = 1101,
  LINEA_CHAIN_ID = 59144,
  AVAX_FUJI_CHAIN_ID = 43113,
}
export const USDC_TOKENS = {
  [CHAIN_ID.POLYGON]: {
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': {
      name: 'USD Coin',
      image: 'https://polygonscan.com/token/images/usdc_32.png',
    },
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': {
      name: 'USD Coin (PoS)',
      image: 'https://polygonscan.com/token/images/usdc_32.png',
    },
  },

  [CHAIN_ID.ETH_SEPOLIA]: ['0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238'],
}

export const NETWORKS: Record<string, IChain> = {
  POLYGON: {
    name: 'Polygon',
    logo: '/icons/chains/polygon-matic-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/4713/large/matic___polygon.jpg?1612939050',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_POLYGON_POS),
    rpc_url: import.meta.env.VITE_RPC_URL_POLYGON_POS,
    explorer: 'https://polygonscan.com',
    isActive: true,
    chainId: CHAIN_ID.POLYGON,
    enabled: false,
  },
  ARBITRUM: {
    name: 'Arbitrum',
    logo: '/icons/chains/arbitrum-arb-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Arbitrum Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_ARBITRUM),
    rpc_url: import.meta.env.VITE_RPC_URL_ARBITRUM,
    explorer: 'https://arbiscan.io',
    chainId: CHAIN_ID.ARBITRUM,
    enabled: false,
  },
  BASE: {
    name: 'Base',
    logo: 'https://media.socket.tech/networks/base.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_BASE),
    rpc_url: import.meta.env.VITE_RPC_URL_BASE,
    explorer: 'https://basescan.org',
    chainId: CHAIN_ID.BASE,
    enabled: false,
  },
  MAINNET: {
    name: 'Ethereum',
    logo: '/icons/chains/ethereum-eth-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_MAINNET),
    rpc_url: import.meta.env.VITE_RPC_URL_MAINNET,
    explorer: 'https://etherscan.io',
    isActive: false,
    chainId: CHAIN_ID.MAINNET,
  },

  AVAX: {
    name: 'Avalanche',
    logo: '/icons/chains/avalanche-avax-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/12559/large/coin-round-red.png?1604021818',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_AVAX),
    rpc_url: import.meta.env.VITE_RPC_URL_AVAX,
    explorer: 'https://cchain.explorer.avax.network',
    chainId: CHAIN_ID.AVAX,
  },
  OPTIMISM: {
    name: 'Optimism',
    logo: '/icons/chains/optimism-ethereum-op-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_OPTIMISM),
    rpc_url: import.meta.env.VITE_RPC_URL_OPTIMISM,
    explorer: 'https://optimistic.etherscan.io',
    chainId: CHAIN_ID.OPTIMISM,
    enabled: false,
  },
  ETH_SEPOLIA: {
    name: 'Ethereum Sepolia',
    logo: '/icons/chains/ethereum-eth-logo.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg',
    },
    isTestnet: true,
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_ETH_SEPOLIA),
    rpc_url: import.meta.env.VITE_RPC_URL_ETH_SEPOLIA,
    explorer: 'https://sepolia.etherscan.io',
    isActive: false,
    chainId: CHAIN_ID.ETH_SEPOLIA,
    enabled: false,
  },
  BASE_SEPOLIA: {
    name: 'Base Sepolia',
    logo: 'https://media.socket.tech/networks/base.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_BASE_SEPOLIA),
    rpc_url: import.meta.env.VITE_RPC_URL_BASE_SEPOLIA,
    explorer: 'https://sepolia.basescan.org',
    chainId: CHAIN_ID.BASE_SEPOLIA_CHAIN_ID,
    enabled: true,
    isTestnet: true,
  },
  POLYGON_ZKEVM_TESTNET: {
    name: 'Polygon Testnet',
    logo: 'https://assets.coingecko.com/coins/images/4713/large/matic___polygon.jpg?1612939050',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      icon: 'https://assets.coingecko.com/coins/images/4713/large/matic___polygon.jpg?1612939050',
    },
    isTestnet: true,
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_POLYGON_ZKEVM_TESTNET),
    rpc_url: import.meta.env.VITE_RPC_URL_POLYGON_ZKEVM_TESTNET,
    explorer: 'https://mumbai.polygonscan.com',
    chainId: CHAIN_ID.POLYGON_ZKEVM_TESTNET,
  },
  OP_SEPOLIA_CHAIN_ID: {
    name: 'Optimism Sepolia',
    logo: 'https://media.socket.tech/networks/optimism.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg',
    },
    isTestnet: true,
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_OP_SEPOLIA),
    explorer: 'https://optimistic.etherscan.io/',
    rpc_url: import.meta.env.VITE_RPC_URL_OP_SEPOLIA,
    chainId: CHAIN_ID.OP_SEPOLIA_CHAIN_ID,
    enabled: true,
  },
  ARB_SEPOLIA_CHAIN_ID: {
    name: 'Arbitrum Sepolia',
    logo: 'https://media.socket.tech/networks/arbitrum.svg',
    currency: {
      address: NATIVE_TOKEN_ADDRESS,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      icon: 'https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg',
    },
    rpc: new JsonRpcProvider(import.meta.env.VITE_RPC_URL_ARB_SEPOLIA),
    explorer: 'https://sepolia.arbiscan.io/',
    chainId: CHAIN_ID.ARB_SEPOLIA_CHAIN_ID,
    rpc_url: import.meta.env.VITE_RPC_URL_ARB_SEPOLIA,
    isTestnet: true,
    enabled: true,
  },
} as const
export const NETWORKS_BY_CHAINID = Object.values(NETWORKS).reduce((obj, prop) => {
  ;(obj as any)[prop.chainId] = prop
  return obj
}, {} as { [key in CHAIN_ID]: IChain })
export const getNetwork = (chainId: CHAIN_ID) => NETWORKS_BY_CHAINID[chainId]
export const SIGN_TYPE = {
  Permit: [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}
export const IOS_TRANSITION = `
.slideInRight-enter {
  transform: translate(100%, 0);
  z-index: 1;
  background-color: white;
}

.slideInRight-enter.slideInRight-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
  
.fadeIn-enter {
  opacity:0;
  z-index: 1;
  background-color: white;
}

.fadeIn-enter.fadeIn-enter-active {
  opacity:1;
  transition: opacity 300ms ease;
}
.slideUp-enter {
  transform: translate(0, 100%);
  z-index: 1;
  background-color: white;
}

.slideUp-enter.slideUp-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
`
export const WEB_TRANSITION =
  IOS_TRANSITION +
  ` 
.slideInRight-exit {
  position: absolute;
  top: 0;
  z-index: -1;
}
.slideInRight-exit.slideInRight-out-exit-active {
  transition: transform 300ms ease;
}
.slideInLeft-enter {
  transform: translate(-100%, 0);
  z-index: 1;
  background-color: white;
}

.slideInLeft-enter.slideInLeft-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
.slideInLeft-exit {
  position: absolute;
  top: 0;
  z-index: -1;
}
.slideInLeft-exit.slideInLeft-exit-active {
  transition: transform 300ms ease;
} `
export const MAX_ALLOWANCE_VALUE = 10 ** 15
export const MIN_ALLOWANCE_VALUE = 10 ** 10
