import { INotification, NotificationType, PaymentService, notificationService } from '@/api'
import { popup } from '@/popup_handler'
import { TitleBar } from '@/ui/titlebar'
import { HIDE, SHOW } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { Bell } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const Notifications = () => {
  const navigation = useNavigate()
  const notifications = useQuery(['notifications'], () => notificationService.GetAll())
  const handleNavigatte = async (notification: INotification) => {
    switch (notification.type) {
      case NotificationType.TRANSACTION_IN:
      case NotificationType.TRANSACTION_OUT:
        return popup.open('payment_receipt', {
          _id: notification.metadata.objectId,
        })
      case NotificationType.MESSAGE:
        const userData = await PaymentService.GetUserByUserId(notification.metadata.remoteUser)
        navigation(`/dashboard/chat/${userData.result.address}`)
    }
  }
  return (
    <div className="flex flex-col gap-4  p-4 h-full w-full">
      <TitleBar title="Notifications" navClassName="sticky top-0" />
      <div {...HIDE(notifications.data?.result?.length)} className="flex flex-col gap-2 items-center justify-center w-full h-full">
        <Bell width={96} height={96} className=" text-gray-300" />
        <h4 className="text-gray-400">No Notification</h4>
      </div>
      <div {...SHOW(notifications.data?.result?.length)} className="flex flex-col gap-2">
        {notifications.data?.result.map((notification: INotification) => (
          <button onClick={() => handleNavigatte(notification)} key={notification._id} className="w-full flex flex-col shadow-[0px_10px_20px_0px_rgba(0,0,0,0.05)] bg-white p-2">
            <p className="font-bold">{notification.title}</p>
            <p>{notification.description}</p>
          </button>
        ))}
      </div>
    </div>
  )
}
export default Notifications
