import { get, post } from '@/utils/xhr'

class History {
  GetById = async (_id: string) => {
    return (await get(`/pay/${_id}`))
  }
  Get = async (query?: any) => {
    return (
      await get(`/pay/`, {
        params: query,
      })
    ).result
  }
  Track = (payload: any) => {
    return post('/history/', payload)
  }
  MarkComplate = (hash: string) => {
    return post('/history/mark-complete', {
      hash,
    })
  }
}
export const HistoryService = new History()
