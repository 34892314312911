import { StoreApi, UseBoundStore, create } from 'zustand'
import { WalletSlice, IWalletSlice } from './wallet'
import { UserSlice } from './user'
const getStore = () => {
  /**hack: it will not flush store state in development on rerender */
  if ((window as any).store) return (window as any).store
  ;(window as any).store = create<IWalletSlice>((...a) => ({
    ...WalletSlice(...a),
    ...UserSlice(...a),
  }))
  return (window as any).store
}
export const useAppStore = getStore() as UseBoundStore<StoreApi<IWalletSlice>>
