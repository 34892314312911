import { PaymentService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useToast } from '@/cn/ui/use-toast'
import { TitleBar } from '@/ui/titlebar'
import { NativeBridge } from '@/utils/native_bridge'
import { Clipboard } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SendToCPIID = () => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const { toast } = useToast()
  const navigate = useNavigate()
  const handleContinue = async (e: any) => {
    setLoading(true)
    e.preventDefault()
    try {
      const {
        result: { user },
      } = await PaymentService.GetUserAddressByCPID(value)
      if (!user) {
        return toast({
          variant: 'destructive',
          title: 'User not found',
        })
      }
      navigate(`/dashboard/chat/${user.address}`)
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: error.message,
      })
    } finally {
      setLoading(false)
    }
  }
  const handlePaste = async () => {
    try {
      let text
      if (NativeBridge.isBridge()) {
        text = (await NativeBridge.Send('PASTE')) as string
      } else {
        text = await navigator.clipboard.readText()
      }
      setValue(text)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="flex flex-col gap-8  p-4 h-full text-foreground w-full">
      <TitleBar title="" />
      <form onSubmit={handleContinue} className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 py-2 ">
          <div className="grid w-full items-center gap-1.5">
            <h3>Enter CPI ID</h3>
            <div className="flex items-center gap-2 w-full">
              <Input
                {...(!value && {
                  icon: <Clipboard className="cursor-pointer active:text-primary" onClick={() => handlePaste()} />,
                })}
                placeholder="vitalik"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                iconRight
              />
            </div>
          </div>
          <Button loading={loading} type="submit" className="mt-2 w-full">
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
export default SendToCPIID
