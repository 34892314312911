import { WalletService } from '@/api/wallet'
import { WALLET, queryClient } from '../utils'
import { IWallet } from '@/interface'
import { NativeBridge } from '@/utils/native_bridge'
//@ts-ignore
export interface IWalletSlice {
  wallets: IWallet[]
  wallet: null | IWallet
  is_loading: boolean
  resetWallets: Function
  setWalletBackedUp: Function
  loadWallets: () => Promise<void>
  createWallet: () => Promise<void>
  importWallet: Function
}
//@ts-ignore
export const WalletSlice = (set: any, ...params: any): IWalletSlice => ({
  wallets: [],
  wallet: null,
  is_loading: true,
  resetWallets: () => {
    set(() => ({
      wallets: [],
      wallet: null,
    }))
  },
  setWalletBackedUp: () => {
    set((state: any) => {
      state.wallet = {
        ...state.wallet,
        backedup_done: true,
      }
      state.wallets = state.wallets.map((_: any) => ({
        ..._,
        backedup_done: true,
      }))
      return {
        ...state,
      }
    })
  },
  loadWallets: async () => {
    try {
      const isNative = NativeBridge.isBridge()
      const { _id: userId } = queryClient.getQueryState(['user'])?.data as any
      set(() => ({
        is_loading: true,
      }))
      const walletsRes = (await WalletService.GetAll())?.result
      let nativeWalletPassphrase: any
      if (NativeBridge.isBridge()) {
        nativeWalletPassphrase = await NativeBridge.Send('MNEMONIC_PHRASE_STATUS')
      }
      const localWallts = Object.values(WALLET.GetAll())
        .filter((_: any) => _.userId === userId)
        .reduce((obj: any, prop: any) => {
          obj[prop._id] = prop
          return obj
        }, {}) as any
      const wallets = walletsRes
        ? [
            {
              ...walletsRes,
              is_local: isNative ? nativeWalletPassphrase : walletsRes?._id in localWallts,
              mnemonicCipher: localWallts?.[walletsRes._id]?.mnemonicCipher /** encrypted mnemonic, only in the webapp */,
              userId,
            },
          ]
        : [] /** each user will have only one wallet instance. creating wallets array for backward compatibility, we are using it at many places */

      const wallet = wallets[0]

      set(() => ({
        wallets: wallets,
        is_loading: false,
        wallet,
      }))
    } catch (error) {
      console.log(error)
    }
  },
  createWallet: async () => {
    try {
      let wallet = await WALLET.Create()
      set((state: any) => ({
        wallets: [...state.wallets, { ...wallet, is_local: true }],
        wallet: { ...wallet, is_local: true },
      }))
    } catch (error) {
      console.log(error)
    }
  },
})
