import { Button } from '@/cn/ui/button'
import { PasskeyUtil } from '@/utils/passkey'
import { Navigate, useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  const passkeyUsers = PasskeyUtil.LoggedUser()
  let query = new URLSearchParams(window.location.search)
  let notification = query.get('notification')
  if (passkeyUsers.length) {
    return <Navigate to={'/auth' + (notification ? `?notification=${notification}` : '')} />
  }
  return (
    <div
      className="flex flex-col items-center justify-between h-full  relative text-foreground"
      style={{ background: 'linear-gradient(347deg, #FFF -44.51%, #F96900 -44.51%, #FEE4D2 -44.51%, #FA8A38 69.42%, #F96900 101.78%)' }}
    >
      <img className=" mt-12  w-full" src="/welcome-illustration.svg" />
      <div className="flex flex-col justify-between flex-grow mt-8">
        <img src="/welcome-text.svg" />
        <div className="flex flex-col  w-full justify-between items-center p-4 gap-2 pb-8">
          <Button onClick={() => navigate('auth')} className=" w-full" variant="secondary">
            CREATE ACCOUNT
          </Button>
          <Button onClick={() => navigate('auth#signin')} className=" w-full" variant="secondary">
            LOGIN
          </Button>
        </div>
      </div>
    </div>
  )
}
export default Home
