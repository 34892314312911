import { PaymentService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useToast } from '@/cn/ui/use-toast'
import { NETWORKS_BY_CHAINID } from '@/constants'
import { TitleBar } from '@/ui/titlebar'
import { handleQrScan } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'
import { ethers } from 'ethers'
import { Clipboard, QrCode, X } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SendToAddress = () => {
  const navigate = useNavigate()

  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const { toast } = useToast()
  const isValidAddress = useMemo(() => ethers.isAddress(value), [value])
  const handleContinue = async (e: any) => {
    e.preventDefault()
    if (!value) {
      return
    }
    try {
      setLoading(true)
      const isValidAddress = ethers.isAddress(value)
      if (isValidAddress) {
        const user = await PaymentService.GetUserAddressByAddress(value)
        if (user.result.user) {
          return navigate('/dashboard/send-money', {
            state: {
              address: user.result.address,
              username: user.result.cpiid,
              name: user.result.name,
              userId: user.result.userId,
            },
          })
        } else {
          return navigate('/dashboard/send-money', {
            state: {
              address: value,
            },
          })
        }
      }
      const p = NETWORKS_BY_CHAINID[1].rpc
      //@ts-ignore
      if (value.endsWith('.eth')) {
        let address = await p.resolveName(value)
        if (!address && value) {
          toast({
            variant: 'destructive',
            title: "Can't resolve address",
          })
        } else {
          navigate('/dashboard/send-money', {
            state: {
              ens: value,
              address,
            },
          })
        }
      } else {
        toast({ variant: 'destructive', title: 'Invalid Address' })
      }
    } catch (error) {
      console.log(error)
      toast({
        variant: 'destructive',
        title: 'Something went wrong, please try again',
      })
    } finally {
      setLoading(false)
    }
  }
  const handlePaste = async () => {
    try {
      let text
      if (NativeBridge.isBridge()) {
        text = (await NativeBridge.Send('PASTE')) as string
      } else {
        text = (await navigator.clipboard.readText()).trim()
      }
      setValue(text)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="flex flex-col gap-8  p-4 h-full text-foreground w-full">
      <TitleBar title={''} />
      <form onSubmit={handleContinue} className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 py-2 ">
          <div className="grid w-full items-center gap-1.5">
            <h3>Enter Wallet address</h3>

            <div className="flex items-center gap-2 w-full">
              {isValidAddress ? (
                <div className=" border border-primary px-2 py-2 rounded-xl text-xs w-full">
                  <p>Address</p>
                  <div className="flex justify-between items-center">
                    {value}
                    <X onClick={() => setValue('')} className=" cursor-pointer p-1 hover:bg-gray-300 rounded-full" />
                  </div>
                </div>
              ) : (
                <Input
                  {...(!value && {
                    icon: <Clipboard className="cursor-pointer active:text-primary" onClick={() => handlePaste()} />,
                  })}
                  placeholder="0xab5801a7d398351b8be11c439e05c5b3259aec9b"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  iconRight
                />
              )}
            </div>
          </div>

          <Button loading={loading} type="submit" className="mt-2 w-full">
            Continue
          </Button>
          <div className="flex items-center gap-2 px-4 mt-12">
            <div className="h-[1px] w-full bg-[#ADADAD]" />
            Or
            <div className="h-[1px] w-full bg-[#ADADAD]" />
          </div>
          <button className="flex gap-2 mt-4 mx-auto " onClick={() => handleQrScan({ navigation: navigate })}>
            <QrCode /> Scan QR Code
          </button>
        </div>
      </form>
    </div>
  )
}
export default SendToAddress
