import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const TitleBar = ({ title, navClassName = '' }: { title?: string; navClassName?: string }) => {
  const navigate = useNavigate()
  return (
    <div className={twMerge(`flex items-center bg-background gap-4 text-foreground`, navClassName)}>
      <button onClick={() => navigate('..')} className="rounded-full p-1">
        <ArrowLeft />
      </button>
      <p className="pl-4 text-lg font-bold">{title}</p>
    </div>
  )
}
