import ENV from '@/config'
import axios from 'axios'

class PayWidget {
  Status = (orderId: string, API_KEY: string) =>
    axios.get(`${ENV.HOST}/orders/status/${orderId}`, {
      headers: {
        apiKey: API_KEY,
      },
    })
  Create = (data: any, istestnet: boolean, API_KEY: string) =>
    axios.post(`${ENV.HOST}/orders`, data, {
      headers: {
        apiKey: API_KEY,
        istestnet,
      },
    })
}
export const payWidgetService = new PayWidget()
