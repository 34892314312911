import { AuthService } from '@/api'
import { Button } from '@/cn/ui/button'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/cn/ui/input-otp'
import { useToast } from '@/cn/ui/use-toast'
import { queryClient } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
const AuthOtpScreen = ({ state, prev }: any) => {
  const { toast } = useToast()
  const { handleSubmit, setValue } = useForm<{ otp: string }>()
  const { email, name } = state.data
  const mutation = useMutation(
    async (payload: { otp: string }) => {
      if (state.data.is_login) {
        return AuthService.Login({
          email,
          otp: payload.otp,
        })
      } else {
        return AuthService.Register({
          email,
          otp: payload.otp,
          name,
        })
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries()
      },
      onError: (data: any) => {
        toast({
          title: data.message,
          variant: 'destructive',
        })
      },
    }
  )
  const handleNext = (payload: { otp: string }) => {
    mutation.mutate(payload)
  }

  return (
    <form onSubmit={handleSubmit(handleNext)} className="flex flex-col gap-4 h-full bg-background text-foreground justify-center">
      <div className=" mx-4 mb-4 flex flex-col gap-4 justify-end items-center">
        <h2>We’ve emailed you.</h2>
        <p className="text-sm text-center">To verify your account, we’ve sent you anone time password (OTP) to the email you provided.</p>
      </div>
      <div className=" mx-auto w-full px-4">
        <h5 className="text-center mb-4">Enter OTP</h5>
        <InputOTP className="w-full" onChange={(otp) => setValue('otp', otp)} maxLength={6}>
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        <div className="pt-8 flex flex-col gap-4">
          <Button loading={mutation.isLoading} className="w-full ">
            Continue
          </Button>
          <Button onClick={() => prev()} variant="secondary" className="w-full">
            Back
          </Button>
        </div>
      </div>
    </form>
  )
}
export default AuthOtpScreen
