import { PaymentService } from '@/api'
import { useAppStore } from '@/store'
import { useQuery } from '@tanstack/react-query'
export const useAllowance = () => {
  const { wallet } = useAppStore((state: any) => state) as any

  return useQuery(['allowance', wallet?.address], () => PaymentService.getAllowanceStatus(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!wallet?.address,
  })
}
