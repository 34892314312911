import { Navigate, useLocation } from 'react-router-dom'
import SendMoney from './send_money_home'

const SendHome = () => {
  const location = useLocation()
  if (!location.state) {
    return <Navigate to="/dashboard" />
  }
  return <SendMoney />
}
export default SendHome
